export const ContactFormMessage = {
  form: {
    sent: 'Tin nhắn của bạn đã được gửi',
    error: 'Lỗi: đã có lỗi xảy ra trong quá trình gửi. Vui lòng thử lại sau',
  },
  name: {
    required: 'Bạn vui lòng nhập tên!',
  },
  email: {
    required: 'Bạn vui lòng nhập email!',
    invalid:
      'Định đạng email không hợp lệ. Định dạng hợp lệ: email-cua-ban@ten-mien.xyz!',
  },
  phone: {
    required: 'Bạn vui lòng nhập số điện thoại!',
    max: 'Số điện thoại không thể vượt quá 10 chữ số!',
    invalid: 'Số điện thoại không hợp lệ',
  },
  files: {
    error: {
      invalid: 'Vui lòng kiểm tra lại và xoá tệp tin không hợp lệ!',
    },
  },
  recaptcha: {
    required: 'Vui lòng xác minh bạn không phải là robot!',
  },
  acceptedTerms: {
    oneOf: 'Vui lòng đồng ý với Chính sách bảo mật của chúng tôi.',
  },
};
