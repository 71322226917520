import React from 'react';

const FormFieldWrapper = ({className, children}) => {
  return (
    <div className={` flex flex-col gap-0.5 pb-4 ${className ?? ''}`}>
      {children}
    </div>
  );
};

export default FormFieldWrapper;
