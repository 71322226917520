import React from 'react';
import {TailwindTransitions} from '../../../utils/library/transitions/transitions';

/**
 * A Progress bar component
 * @param {Object} props
 * @param {Number} props.progress Current progress of progress bar. Within range 0-100
 * @param {String} props.label Label of progress bar
 * @param {String} props.height Tailwind class specify the height of progress bar. Default is 'h-4'
 * @param {String} props.textColor Tailwind class specify the label color of progress bar. Default is 'text-white'
 * @param {String} props.progressColor Tailwind class specify the background color of current progress in progress bar. Default is 'bg-primary'
 * @param {String} props.bgColor Tailwind class specify the background color of progress bar. Default is 'bg-gray-200'
 * @returns
 */
const ProgressBar = ({
  progress,
  label,
  height = 'h-4',
  textColor = 'text-white',
  progressColor = 'bg-primary',
  bgColor = 'bg-gray-200',
  className = '',
}) => {
  return (
    <>
      <div className={`${height} ${bgColor} ${className} w-full rounded-full`}>
        <div
          className={`${
            TailwindTransitions.default[300]
          } ${height} ${progressColor} ${textColor} ${
            progress === 0 && 'hidden'
          } rounded-full p-0.5 text-center text-xs font-medium leading-none`}
          style={{width: progress + '%'}}
        >
          {label ?? ''}
        </div>
      </div>
    </>
  );
};

export default ProgressBar;
