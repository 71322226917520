import React from 'react';
import {TailwindTransitions} from '../../../utils/library/transitions/transitions';

const TextareaField = (props) => {
  return (
    <textarea
      {...props}
      className={`${TailwindTransitions.form.field.default} ${props.className} caret-primary`}
    />
  );
};

export default TextareaField;
