import React from 'react';

/**
 *
 * @param {Object} props
 * @param {()=>{}} props.customizeOnBlur Customize onBlur function
 * @returns
 */
const InputField = ({customizeOnBlur, ...props}) => {
  return (
    <input
      // {...field}
      {...props}
      onBlur={customizeOnBlur ?? props.onBlur}
      className={`${props.className}  bg-transparent p-1 pl-0 text-gray-800 caret-primary focus:outline-none`}
    />
  );
};

export default InputField;
