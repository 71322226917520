import {BiTrash} from '@react-icons/all-files/bi/BiTrash';
import axios from 'axios';
import React, {useEffect, useState} from 'react';
import PlaceholderImage from '../../../assets/placeholders/placeholder-720-480.png';
import AppConfig from '../../../config/appConfig';
import {TailwindTransitions} from '../../../utils/library/transitions/transitions';
import FilePreview from '../FilePreview/FilePreview';
import ProgressBar from '../ProgressBar/ProgressBar';
import DropzoneErrorMessages from './dropzone-error-msg';
import {BsDot} from '@react-icons/all-files/bs/BsDot';
import {getFileSizeString} from '../../../utils/converter';

/**
 * A Component display/upload/delete file for DropzoneField component
 * @param {Object} props props
 * @param {File} props.file File to be uploaded
 * @param {String} props.fileName User defined file name. The function will use original file name if this param is not specified. However, it's best practice to set a unique file name to avoid overwritten!
 * @param {Boolean} props.isUploaded Upload status of this file
 * @param {(file: File)=>{}} props.onDelete Callback when delete file
 * @param {(file: File, url: String)=>{}} props.onUpload Callback when file upload successfully
 * @param {(file: File, error)=>{}} props.onError Callback when file has error
 * @param {Boolean} props.isShowPreview Should displays preview thumbnail or not
 * @param {Object[]} props.errors List errors of this file
 * @param {String} props.errors.code Error code
 * @param {String} props.errors.message Error message
 * @returns
 */
const DropzoneUploadSingleFile = ({
  file,
  fileName,
  isUploaded,
  onDelete = (file) => {},
  onUpload = (file, url) => {},
  onError = (file, error) => {},
  isShowPreview,
  errors,
}) => {
  const [progress, setProgress] = useState(0);
  const [isConnecting, setIsConnecting] = useState(-1);
  const [isProcessing, setIsProcessing] = useState(-1);

  useEffect(() => {
    async function upload() {
      // not process upload if file already uploaded or has errors
      if (isUploaded || errors.length > 0) return;

      // Check network condition
      if (typeof window !== 'undefined' && !window.navigator.onLine) {
        // setErrors((prev) => [...prev, {code: 'network-err'}]);
        onError(file, {code: 'network-err'});
        return;
      }

      try {
        const url = await uploadFileToStorage(
          file,
          fileName,
          setIsConnecting,
          setProgress,
          setIsProcessing
        );
        // Notify parent
        onUpload(file, url);
      } catch (error) {
        // Handle network error
        if (typeof window !== 'undefined' && !window.navigator.onLine) {
          onError(file, {code: 'network-err'});
          return;
        } else {
          // Unknown error
          onError(file, {code: 'unknown-err'});
          return;
        }
      }
    }

    upload();
  }, [file, fileName, errors, isUploaded, onError, onUpload]);

  /**
   * Render status
   */
  let renderStatus;

  if (isConnecting === 0) {
    renderStatus = (
      <>
        <span className="text-neutral-500">Đang kết nối</span>
        <BsDot size={16} className="mx-1" />
      </>
    );
  } else if (isConnecting === 1 && progress > 0 && progress < 100) {
    renderStatus = (
      <>
        <span className="text-primary">Đang tải lên</span>
        <BsDot size={16} className="mx-1" />
      </>
    );
  } else if (progress === 100 && isProcessing === 0) {
    renderStatus = (
      <>
        <span className="text-primary">Đang xử lý</span>
        <BsDot size={16} className="mx-1" />
      </>
    );
  } else if (isUploaded || (progress === 100 && isProcessing === 1)) {
    renderStatus = (
      <>
        <span className="text-green-500">Xong</span>
        <BsDot size={16} className="mx-1" />
      </>
    );
  }

  let isFileError = errors.length > 0;
  let isShowClearBtn = isUploaded || isProcessing === 1 || isFileError;
  let calculatedFileName = fileName?.trim().length !== 0 ? fileName : file.name;

  return (
    file && (
      <div className="border-b border-neutral-300 pb-2 pt-1 first:pt-0 last:mb-0 last:border-none last:pb-0">
        {/* File name & Action button */}
        <div className="mb-1 flex flex-row items-center justify-between">
          <div className="flex w-[90%] flex-row items-center">
            {/* File extension */}
            <span className="mr-1 rounded-md border-2 border-neutral-500 px-1 py-1 text-sm font-bold leading-3 text-neutral-500">
              {getFileExtension(calculatedFileName).slice(1)}
            </span>
            {/* File name */}
            <span className={`truncate ${isFileError && 'text-neutral-400'}`}>
              {file.name}
            </span>
          </div>
          {/* Action button */}
          {isShowClearBtn && (
            <BiTrash
              size={24}
              onClick={() => {
                try {
                  deleteFileInStorage(file, fileName, onDelete);
                } catch (error) {
                  // setErrors((prev) => [...prev, {code: 'unknown-err'}]);
                  onError(file, {code: 'unknown-err'});
                  return;
                }
              }}
              className={`${
                TailwindTransitions.default
              } cursor-pointer rounded-md p-1 ${
                isFileError
                  ? 'text-red-500 hover:bg-red-500 hover:text-white'
                  : 'hover:bg-neutral-200'
              } `}
            />
          )}
        </div>

        {/* File status */}
        {!isFileError && (
          <div className="mb-1 flex flex-row items-center text-sm text-neutral-500">
            {renderStatus}
            <span>{getFileSizeString(file.size)}</span>
          </div>
        )}
        {/* Progress bar */}
        {!isFileError ? (
          <>
            {!isUploaded && progress !== 100 && (
              <ProgressBar progress={progress} label={progress + '%'} />
            )}

            {isShowPreview && (
              <>
                {progress === 100 ? (
                  <FilePreview file={file} width={150} height={85} />
                ) : (
                  <img src={PlaceholderImage} width={150} alt="Placeholder" />
                )}
              </>
            )}
          </>
        ) : (
          <>
            <p className="text-red-500">
              {`Lỗi: `}
              {errors.map((error) => (
                <span key={error.code} className="text-red-500">
                  {DropzoneErrorMessages.errorCode[error.code]}
                </span>
              ))}
            </p>
          </>
        )}
      </div>
    )
  );
};

export default DropzoneUploadSingleFile;

/**
 * Extract file extension from full file name
 * @param {String} fileName
 * @returns file extension
 */
function getFileExtension(fileName) {
  let regex = /(\.[\w\d?=_-]+)$/i;

  return fileName.match(regex)[0];
}

/**
 * Upload a file to company's S3 bucket storage. The bucket must be configured in .env file. Firstly, call API to get upload url for this file. Secondly, upload the file using provided url. Finally, call API to get file sharing url.
 *
 * IMPORTANT: If a file on the S3 bucket has similar name to the to-be-uploaded file, it will be overwritten by the to-be-uploaded file!
 * @param {File} file File to be upload
 * @param {String} fileName User defined file name. The function will use original file name if this param is not specified. However, it's best practice to set a unique file name to avoid overwritten!
 * @param {(status)=>{}} setConnectStatus Set connect status
 * @param {(progress: number)=>{}} onProgress Set current progress percentage
 * @param {(status)=>{}} setProcessStatus Set process status
 * @returns
 */
async function uploadFileToStorage(
  file,
  fileName,
  setConnectStatus = () => {},
  onProgress = () => {},
  setProcessStatus = () => {}
) {
  try {
    const putApiEndpoint = '/api/upload/get-upload-url';
    const getApiEndpoint = '/api/upload/get-file-url';
    const key = 'name';
    const value = fileName?.trim().length !== 0 ? fileName : file.name;

    /**
     * Get PUT url
     */

    // Add interceptor
    const connectInterceptor = axios.interceptors.request.use((config) => {
      setConnectStatus(0);
      return config;
    });

    // Get PUT url
    const putRes = await axios
      .get(`${putApiEndpoint}?${key}=${value}`)
      .then((res) => {
        setConnectStatus(1);
        return res;
      });

    // Clear interceptor
    axios.interceptors.request.eject(connectInterceptor);

    /**
     * Upload file
     */
    const abortController = new AbortController();
    let timeout;

    // Upload file
    await axios
      .put(putRes.data, file, {
        // Axios Configs
        onUploadProgress: ({progress}) => {
          // clear previous timeout
          if (timeout) clearTimeout(timeout);
          // set new timeout
          timeout = setTimeout(
            () => abortController.abort(),
            AppConfig.axiosTimeout ?? 5000
          );
          // update onProgress
          onProgress(Math.round(progress * 100));
        },
        signal: abortController.signal,
      })
      .then((response) => {
        // clear previous timeout
        if (timeout) clearTimeout(timeout);
        return response;
      });

    /**
     * Get share url
     */

    // Add interceptor
    const processInterceptor = axios.interceptors.request.use((config) => {
      setProcessStatus(0);
      return config;
    });

    // Get share url
    const getRes = await axios
      .get(`${getApiEndpoint}?${key}=${value}`)
      .then((res) => {
        setProcessStatus(1);
        return res;
      });

    // Clear interceptor
    axios.interceptors.request.eject(processInterceptor);

    return getRes.data;
  } catch (error) {
    throw new Error(error);
  }
}

/**
 * Delete file on company S3 bucket storage
 * @param {File} file file to be deleted
 * @param {String} fileName Name of file to be deleted
 * @param {(file: File)=>{}} onDelete Callback when delete file
 */
async function deleteFileInStorage(file, fileName, onDelete = (file) => {}) {
  try {
    let deleteFileApiEndpoint = '/api/upload/remove-file';
    const key = 'name';
    const value = fileName;

    axios.get(`${deleteFileApiEndpoint}?${key}=${value}`);

    onDelete(file);
  } catch (error) {
    throw new Error(error);
  }
}
