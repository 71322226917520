const DropzoneErrorMessages = {
  progressBarLabel: 'Lỗi',
  errorCode: {
    'unknown-err': 'Đã có lỗi xảy ra',
    'network-err': 'Đường truyền mạng không ổn định. Vui lòng kiểm tra lại!',
    'file-duplicate': 'Tệp tin này đã được tải lên trước đó',
    'file-invalid-type': 'Định dạng tệp tin không hợp lệ',
    'file-too-large': 'Kích thước tệp tin vượt hạn mức cho phép!',
    'file-too-small': 'Kích thước tệp tin nhỏ hơn hạn mức yêu cầu',
    'too-many-files': 'Bạn đã tải lên quá số tệp tin cho phép',
  },
};
export default DropzoneErrorMessages;
