import {AiOutlineCheckCircle} from '@react-icons/all-files/ai/AiOutlineCheckCircle';
import {BiErrorCircle} from '@react-icons/all-files/bi/BiErrorCircle';
import {HiOutlineArrowLongRight} from '@react-icons/all-files/hi2/HiOutlineArrowLongRight';
import {ErrorMessage, Field, Form, Formik} from 'formik';
import React, {useRef, useState} from 'react';
import Recaptcha from 'react-google-recaptcha';
import {array, boolean, object, string} from 'yup';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import MaterialField from '../Material/MaterialField';
import CardWrapper from '../Wrapper/CardWrapper';
import FormFieldWrapper from '../Wrapper/FormFieldWrapper';
import Button from '../_baseComponents/Button/Button';
import DropzoneField from '../_baseComponents/Dropzone/DropzoneField';
import TextareaField from '../_baseComponents/Field/TextareaField';
import UniversalLink from '../_baseComponents/Link/UniversalLink';
import PopupNotify from '../_baseComponents/Popup/PopupNotify';
import {ContactFormMessage} from './form-message';

/**
 * Initial fields for form
 */
const formInputInitial = {
  name: '',
  email: '',
  phone: '',
  files: [],
  message: '',
  recaptcha: null,
  acceptedTerms: false,
};

/**
 * Set validation for fields
 */
const validationSchema = object({
  name: string().required(ContactFormMessage.name.required),
  email: string()
    .email(ContactFormMessage.email.invalid)
    .required(ContactFormMessage.email.required),
  phone: string()
    .max(10, ContactFormMessage.phone.max)
    .required(ContactFormMessage.phone.required)
    .matches(
      /(84|0[3|5|7|8|9])+([0-9]{8})\b/,
      ContactFormMessage.phone.invalid
    ),
  files: array()
    .of(
      object().shape({
        errors: array(),
      })
    )
    .compact((value) => value.errors.length === 0) // filter out file with errors
    .length(0, ContactFormMessage.files.error.invalid), // valid if no file with errors
  recaptcha: string()
    .nullable()
    .required(ContactFormMessage.recaptcha.required),
  acceptedTerms: boolean().oneOf(
    [true],
    ContactFormMessage.acceptedTerms.oneOf
  ),
});

/**
 * Contact form
 * @returns
 */
const ContactForm = ({className}) => {
  let recaptchaRef = React.createRef();
  let dropzoneRef = useRef(null);

  const [submissionStatus, setSubmissionStatus] = useState(0); // 0: default, 1: sent, -1: error
  const [popupMsg, setPopupMsg] = useState('');

  /**
   *
   * @param {Object} values Formik form values
   * @param {Object[]} values.files Attachments wrappers of the Dropzone
   * @param {File} values.files.file Uploaded file
   * @param {boolean} values.files.uploaded Is file uploaded
   * @param {string} values.files.fileName User-defined file name
   * @param {string} values.files.url File URL on the company S3 bucket
   * @param {Object[]} values.files.errors Errors of this file
   * @param {string} values.files.errors.code Error code
   * @param {import('formik').FormikHelpers} helpers
   */
  const submitHandler = async (values, {setSubmitting, resetForm}) => {
    try {
      const postData = {
        name: values.name,
        phone: values.phone,
        email: values.email,
        message: values.message,
        files: values.files.map((fileWrapper) => fileWrapper.url).join(`\\n`),
      };
      const result = await fetch('/api/forms/contact-form', {
        method: 'POST',
        body: JSON.stringify(postData),
      });

      if (!result.ok) {
        const data = await result.json();
        throw new Error(data.message);
      }

      setSubmissionStatus(1);
      setPopupMsg(ContactFormMessage.form.sent);
      // reset inputs
      resetForm();
      recaptchaRef.current.reset();
      dropzoneRef.current.resetValues();
    } catch (error) {
      setSubmissionStatus(-1);
      setPopupMsg(ContactFormMessage.form.error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <CardWrapper
      className={`${
        className ?? ''
      } flex w-full flex-col gap-6 rounded-xl border-2 border-white bg-white/60 p-5`}
      isShadow={false}
    >
      {/* Title  */}
      <div className="flex flex-col gap-3 lg:pt-3">
        <h1 className="text-2xl font-bolder text-gray-800 md:text-3xl lg:text-4xl">
          Liên hệ
        </h1>
        <p className="text-base font-normal text-gray-800">
          Với dịch vụ FastSupport247 của KDIGI, chúng tôi cam kết đặt lịch tư
          vấn cho quý khách trong vòng 24 giờ kể từ khi nhận được yêu cầu này.
        </p>
      </div>

      <Formik
        initialValues={formInputInitial}
        validationSchema={validationSchema}
        onSubmit={submitHandler}
      >
        {(formik) => {
          const handleRequiredBlur = (e) => {
            if (!formik.values.recaptcha) {
              recaptchaRef.current.execute();
            }
            formik.handleBlur(e);
          };
          return (
            <Form className="">
              {/* Name start */}
              <FormFieldWrapper>
                <MaterialField
                  formik={formik}
                  inputName="name"
                  type="text"
                  placeholder="Tên của bạn (bắt buộc)"
                  handleRequiredBlur={handleRequiredBlur}
                />
              </FormFieldWrapper>

              {/* Phone start */}
              <FormFieldWrapper>
                <MaterialField
                  formik={formik}
                  inputName="phone"
                  type="tel"
                  placeholder="Số điện thoại (bắt buộc)"
                  handleRequiredBlur={handleRequiredBlur}
                />
              </FormFieldWrapper>

              {/* Email Start */}
              <FormFieldWrapper>
                <MaterialField
                  formik={formik}
                  inputName="email"
                  type="email"
                  placeholder="Email (bắt buộc)"
                  handleRequiredBlur={handleRequiredBlur}
                />
              </FormFieldWrapper>

              {/* Message start */}
              <FormFieldWrapper>
                <Field
                  name="message"
                  as={TextareaField}
                  className="w-full resize-none rounded-md border-2 border-white bg-white/60 px-3 py-2 text-gray-500"
                  placeholder="Lời nhắn"
                  cols="20"
                  rows="5 "
                />
              </FormFieldWrapper>

              {/* Files start */}
              <FormFieldWrapper>
                <label htmlFor="files" className="mb-3 block text-neutral-500">
                  Tệp đính kèm
                </label>
                <DropzoneField
                  ref={dropzoneRef}
                  fieldName="files"
                  setValues={formik.setFieldValue}
                  acceptedTypes={{'image/*': []}}
                  note="(Chỉ chấp nhận file ảnh có kích thước nhỏ hơn 1MB)"
                  rejectErrMsg="Tệp không hợp lệ, vui lòng kiểm tra lại"
                  defaultClassName={{
                    root: 'flex flex-col justify-center border-2 border-dashed border-neutral-300 p-2 text-center text-neutral-400 rounded-lg',
                    p: '',
                    em: 'text-sm',
                    icon: 'w-full text-neutral-400',
                  }}
                  rejectClassName={{
                    root: 'flex flex-col justify-center border-2 border-dashed border-red-500/80 p-2 text-center text-red-500/80 rounded-lg',
                    p: '',
                    icon: 'w-full text-red-500',
                  }}
                  acceptClassName={{
                    root: 'flex flex-col justify-center border-2 border-dashed border-primary/80 p-2 text-center text-primary/80 rounded-lg',
                    p: '',
                    icon: 'w-full text-primary/80',
                  }}
                  maxFileSize={1 * 1024 * 1024} // 1MB
                />
                <ErrorMessage
                  name="files"
                  className="mt-2 text-red-500"
                  component="p"
                />
              </FormFieldWrapper>

              {/* Recaptcha start */}
              <FormFieldWrapper>
                <Recaptcha
                  id="recaptcha"
                  {...formik.getFieldProps('recaptcha')}
                  ref={recaptchaRef}
                  sitekey={process.env.GATSBY_GG_RECAPTCHA_SITE_KEY}
                  size="invisible"
                  onChange={(value) => formik.setFieldValue('recaptcha', value)}
                  hl="vi"
                />
                <ErrorMessage
                  name="recaptcha"
                  className="text-red-500"
                  component="p"
                />
              </FormFieldWrapper>

              {/* For policy */}
              <FormFieldWrapper className={`mb-2 flex flex-col gap-2`}>
                <div className="">
                  <label className="flex cursor-pointer gap-1 text-xs md:text-base">
                    <input type="checkbox" className="accent-primary" />
                    {'Tôi đồng ý nhận Email khuyến mãi từ '}
                    <UniversalLink
                      to="/"
                      className={`${TailwindTransitions.textDecoration.underline.leftToRight.color_primary['40%']} text-primary`}
                    >
                      KDIGI
                    </UniversalLink>
                  </label>
                </div>

                <div className="">
                  <label className="flex cursor-pointer gap-1 text-xs md:text-base">
                    <Field
                      type="checkbox"
                      name="acceptedTerms"
                      className="accent-primary"
                    />
                    {'Tôi đồng ý với '}
                    <UniversalLink
                      to="/chinh-sach-bao-mat"
                      className={`${TailwindTransitions.textDecoration.underline.leftToRight.color_primary['40%']} text-primary`}
                    >
                      Chính sách bảo mật
                    </UniversalLink>
                    <span className="text-neutral-400">{' (bắt buộc)'}</span>
                  </label>
                  <ErrorMessage
                    name="acceptedTerms"
                    className="text-red-500"
                    component="p"
                  />
                </div>
              </FormFieldWrapper>

              {/* Submit button */}

              <div className="text-end">
                <Button
                  type="primary"
                  buttonType="submit"
                  disabled={
                    Object.keys(formik.errors).length !== 0 ||
                    formik.isSubmitting
                  }
                  content={formik.isSubmitting ? 'Đang gửi...' : 'Gửi đi'}
                  Icon={ButtonIcon}
                  className="gap-2 px-6 py-2 font-medium lg:py-3"
                />

                {submissionStatus !== 0 && (
                  <PopupNotify
                    message={popupMsg}
                    Icon={submissionStatus === 1 ? SuccessIcon : FailIcon}
                    btnCloseDisplay={false}
                    onPrimaryClick={() => setSubmissionStatus(0)}
                    onOutsideClick={() => setSubmissionStatus(0)}
                  />
                )}
              </div>

              {/* Show recaptcha branding when hide recaptcha bagde */}
              {/* <p className="text-sm italic text-neutral-400">
                {'Trang này được bảo vệ bởi reCAPTCHA. '}
                <span>
                  <a
                    href="https://policies.google.com/privacy?hl=vi"
                    className="text-primary/70"
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    Chính sách bảo mật
                  </a>
                  {' và '}
                  <a
                    href="https://policies.google.com/terms?hl=vi"
                    className="text-primary/70"
                    target={'_blank'}
                    rel={'noreferrer'}
                  >
                    Điều khoản dịch vụ
                  </a>
                  {' của Google được áp dụng.'}
                </span>
              </p> */}
            </Form>
          );
        }}
      </Formik>
    </CardWrapper>
  );
};

export default ContactForm;

const SuccessIcon = () => (
  <AiOutlineCheckCircle
    size="1rem"
    className="mx-auto mb-4 h-14 w-14 text-green-500"
  />
);
const FailIcon = () => (
  <BiErrorCircle size="1rem" className="mx-auto mb-4 h-14 w-14 text-red-500" />
);

const ButtonIcon = () => <HiOutlineArrowLongRight className="h-6 w-6" />;
