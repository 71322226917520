import React, {useEffect} from 'react';
import ImgPlaceholder from '../../../assets/placeholders/placeholder-720-480.png';

/**
 * Display a preview thumbnail for file of `image` and `video` type. Display a placeholder thumbnail for other types.
 * @param {Object} props
 * @param {File} props.file File to display
 * @param {Number} props.width Width of preview thumbnail
 * @param {Number} props.height Height of preview thumbnail
 * @returns
 */
const FilePreview = ({file, width = 300, height = 170}) => {
  const preview = URL.createObjectURL(file);
  const fileType = file.type.toLowerCase().substr(0, file.type.indexOf('/'));

  useEffect(() => {
    // Revoke data url to avoid memory leaks
    return () => URL.revokeObjectURL(preview);
  }, [preview]);

  let renderResult = <></>;

  let commonClasses = 'rounded-md';

  switch (fileType) {
    case 'image':
      renderResult = (
        <img
          src={preview}
          alt={'File review'}
          width={width}
          height={height}
          className={`${commonClasses}`}
          onLoad={() => {
            URL.revokeObjectURL(preview);
          }}
        />
      );
      break;

    case 'video':
      renderResult = (
        <video
          src={preview}
          width={width}
          height={height}
          className={`${commonClasses}`}
          onLoad={() => {
            URL.revokeObjectURL(preview);
          }}
        />
      );
      break;
    default:
      renderResult = (
        <img
          src={ImgPlaceholder}
          alt={'File review'}
          width={width}
          height={height}
          className={`${commonClasses}`}
          onLoad={() => {
            URL.revokeObjectURL(preview);
          }}
        />
      );
      break;
  }

  return <>{renderResult}</>;
};

export default FilePreview;
