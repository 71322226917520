import React, {useEffect} from 'react';
import useKeydownHandler from '../../hooks/useKeydownHandler';
import {
  accessibleOnClick,
  preventBackgroundScroll,
} from '../../utils/helperFunctions';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import {ConstOverlayTransitionTypes} from '../../_consts/overlay-transition-consts';

// Default configs for the Overlay Wrapper
let defaultConfig = {
  // Set overlay background
  background: {
    before: 'bg-white/80', // before transition
    after: 'bg-white/80', // after transition
  },
  transition: 'default',
};

/**
 * Create an overlay wrapper that configurable using Tailwind classes. Please check `defaultConfig` in this component for more information.
 * @param {Object} props
 * @param {boolean} props.isDisplaying Show/hide overlay
 * @param {Object} props.config Config overlay
 * @param {Object} props.config.background Config background
 * @param {string} props.config.background.before Background before showing
 * @param {string} props.config.background.after Background after showing
 * @param {Object} props.config.transition Transition type: `default`
 * @param {string} props.ariaLabel `aria-label` value
 * @param {()=>{}} props.onBgClicked Handler when click on background
 * @param {()=>{}} props.onEscPressed Handler when click on background
 * @returns
 */
const OverlayWrapper = ({
  isDisplaying,
  children,
  config = defaultConfig,
  className,
  ariaLabel = 'background',
  onBgClicked,
  onEscPressed,
}) => {
  const isSSR = typeof window === 'undefined';

  // Merge config with default config to avoid `undefined` properties
  config = {...defaultConfig, ...config};

  // Handle `Esc` key clicked
  useKeydownHandler('Escape', onEscPressed);

  useEffect(() => {
    if (isSSR) return;

    preventBackgroundScroll(isDisplaying);

    return () => {
      // remove on unmount
      preventBackgroundScroll(false);
    };
  }, [isDisplaying, isSSR, onEscPressed]);

  let renderTransition = {
    default: '',
    before: '',
    after: '',
  };

  switch (config.transition) {
    case ConstOverlayTransitionTypes.default:
      renderTransition = {
        default: 'transition-all duration-500  ',
        before: 'h-1 w-1 -left-1 -top-1',
        after: 'right-0 top-0 h-screen w-screen',
      };
      break;

    case ConstOverlayTransitionTypes.slideIn.fromRight:
      renderTransition = {
        default: `top-0 h-screen w-screen ${TailwindTransitions.default[300]}`,
        before: 'left-full',
        after: 'left-0',
      };
      break;
    case ConstOverlayTransitionTypes.slideIn.fadeIn:
      renderTransition = {
        default: `${TailwindTransitions.default[300]}`,
        before: '',
        after: 'h-screen w-screen',
      };
      break;
    default:
      break;
  }
  // ${renderTransition.default ?? ''}
  return (
    <div
      {...accessibleOnClick(onBgClicked, null, ariaLabel)}
      className={`${className ?? ''} fixed z-20 cursor-auto backdrop-blur
      ${renderTransition.default ?? ''}
      ${
        !isDisplaying
          ? `${config.background.before} ${renderTransition.before}`
          : `${config.background.after} ${renderTransition.after}`
      }`}
    >
      {isDisplaying && children}
    </div>
  );
};

export default OverlayWrapper;
