/**
 * All transition types of OverlayWrapper
 * @typedef {Object} OverlayTransitionTypes
 * @property {string} default Default transition
 * @property {Object} slideIn Slide In transition
 * @property {string} slideIn.fromRight Slide In from right to left
 * @property {string} none No transition
 *
 */
const ConstOverlayTransitionTypes = {
  default: 'default',
  slideIn: {
    fromRight: 'slideRtoL',
    fadeIn: 'fadeIn',
  },

  none: 'none',
};

module.exports = {ConstOverlayTransitionTypes};
