import {TbMail} from '@react-icons/all-files/tb/TbMail';
import {TbMapPin} from '@react-icons/all-files/tb/TbMapPin';
import {TbPhone} from '@react-icons/all-files/tb/TbPhone';
import {graphql} from 'gatsby';
import {StaticImage} from 'gatsby-plugin-image';
import React from 'react';
import ContactForm from '../components/ContactForm/ContactForm';
import Seo from '../components/Seo/Seo';
import SocialIcons from '../components/SocialIcons/SocialIcons';
import BoxWrapper from '../components/Wrapper/BoxWrapper';
import CardWrapper from '../components/Wrapper/CardWrapper';

const ContactPage = () => {
  return (
    <BoxWrapper className="mx-5 flex gap-5 pt-[70px] md:mx-8 lg:mx-auto lg:max-w-7xl lg:pt-20">
      <div className="flex w-full flex-col-reverse pt-6 lg:flex-row lg:gap-8">
        {/* Bg effect */}
        <div className="relative before:absolute before:bottom-[350px] before:left-5 before:-z-10 before:block before:h-[400px] before:w-[400px] before:rounded-full before:bg-red-600/30 before:blur-[7.5rem] before:will-change-[filter] before:md:-left-[100px] before:md:-top-[500px] before:md:h-[70vw] before:md:w-[70vw] before:md:bg-blue-500/30 before:md:blur-[7.5rem] before:lg:-left-[250px] before:lg:-top-[150px] before:lg:h-[1000px] before:lg:w-[1000px] before:lg:bg-blue-400/20 before:lg:blur-[5rem]" />

        <ContactForm className="mt-0 w-full lg:w-1/2" />
        <div className="mb-8 mt-8 flex flex-col items-center justify-center md:mt-10 md:flex-row md:gap-2 lg:mt-0 lg:w-1/2 lg:flex-col lg:gap-8">
          {/* Hidden on mobile */}
          <div className="hidden md:flex">
            <StaticImage
              src="../assets/contact/contact-conversation.png"
              alt="Conversation between male and female"
              width={600}
              className="md:min-w-[240px] md:max-w-[340px] lg:w-[360px]"
            />
          </div>
          <div className="flex w-full flex-col gap-3 md:min-w-[360px] md:max-w-[510px]">
            <h1 className="text-2xl font-bolder text-gray-800 md:text-3xl lg:text-4xl">
              Thông tin liên hệ
            </h1>
            <CardWrapper
              className={`flex flex-col gap-2 border-2 border-white bg-white/60 px-4 py-3 text-gray-700`}
              isShadow={false}
            >
              {/* For Can Tho Contact */}
              <div className="flex w-full flex-col">
                <h4 className="text-base font-bolder lg:text-lg ">
                  TP. Cần Thơ
                </h4>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <TbMapPin />
                  <p className="w-fit text-base font-normal">
                    Số 69, Đ. 8C2, KDC Hưng Phú 1, Q. Cái Răng, TP. Cần Thơ
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <TbPhone />
                  <a
                    href="tel:0333111868"
                    rel="noreferrer"
                    target="_blank"
                    className={`w-fit text-base font-normal`}
                  >
                    0333.111.868
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <TbMail />
                  <a
                    href="mailto:lienhe@kdigi.vn"
                    rel="noreferrer"
                    target="_blank"
                    className={` w-fit text-base font-normal`}
                  >
                    lienhe@kdigi.vn
                  </a>
                </div>
              </div>
            </CardWrapper>

            <CardWrapper
              className={`flex flex-col gap-2 border-2 border-white bg-white/60 px-4 py-3 text-gray-700`}
              isShadow={false}
            >
              {/* For Ho CHi Minh Contact */}
              <div className="flex w-full flex-col">
                <h4 className="text-base font-bolder lg:text-lg ">
                  TP. Hồ Chí Minh
                </h4>
              </div>
              <div className="flex flex-col gap-2">
                <div className="flex items-center gap-2">
                  <TbMapPin />
                  <p className="w-fit text-base font-normal">
                    Số 45, Đ. Đinh Tiên Hoàng, P. Bến Nghé, Q. 1, TP. HCM
                  </p>
                </div>
                <div className="flex items-center gap-2">
                  <TbPhone />
                  <a
                    href="tel:0333111868"
                    rel="noreferrer"
                    target="_blank"
                    className={`w-fit text-base font-normal`}
                  >
                    0868.567.868
                  </a>
                </div>
                <div className="flex items-center gap-2">
                  <TbMail />
                  <a
                    href="mailto:lienhe@kdigi.vn"
                    rel="noreferrer"
                    target="_blank"
                    className={` w-fit text-base font-normal`}
                  >
                    lienhe@kdigi.vn
                  </a>
                </div>
              </div>
            </CardWrapper>
            <div className="flex flex-col gap-3 md:flex-row lg:items-center lg:justify-between ">
              <div className="font-medium">
                <p>Bạn có thể tìm thấy chúng tôi tại:</p>
              </div>
              <div>
                <SocialIcons />
              </div>
            </div>
          </div>
        </div>
        {/* Bg effect */}
        <div className="relative before:absolute before:right-0 before:top-0 before:-z-10 before:block before:h-[70vw] before:w-[70vw] before:rounded-full before:bg-blue-600/30 before:blur-[7.5rem] before:will-change-[filter] before:md:-bottom-[10vw] before:md:left-[30vw] before:md:h-[70vw] before:md:w-[70vw] before:md:bg-red-500/30 before:md:blur-[7.5rem] before:lg:-bottom-[300px] before:lg:-right-[300px] before:lg:left-auto before:lg:top-auto before:lg:h-[800px] before:lg:w-[800px] before:lg:bg-red-600/10 before:lg:blur-[5rem]" />
      </div>
    </BoxWrapper>
  );
};

export default ContactPage;

export const Head = ({data: {wpPage}}) => {
  return (
    <Seo
      yoastSeo={wpPage?.seo}
      title={wpPage?.title}
      uri={wpPage?.uri}
      description={wpPage?.content && wpPage.content.substring(150)}
    />
  );
};

export const pageQuery = graphql`
  query {
    wpPage(uri: {eq: "/lien-he"}) {
      id
      uri
      title
      content
      seo {
        fullHead
        canonical
      }
    }
  }
`;
