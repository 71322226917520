import React, {memo} from 'react';
import {ImPinterest2} from '@react-icons/all-files/im/ImPinterest2';
import {AiOutlineReddit} from '@react-icons/all-files/ai/AiOutlineReddit';
import {CgMail} from '@react-icons/all-files/cg/CgMail';
import {ImFacebook} from '@react-icons/all-files/im/ImFacebook';
import {FaLinkedin} from '@react-icons/all-files/fa/FaLinkedin';
import {FaTelegram} from '@react-icons/all-files/fa/FaTelegram';
import {FaTwitter} from '@react-icons/all-files/fa/FaTwitter';
import {BiLinkAlt} from '@react-icons/all-files/bi/BiLinkAlt';
import {RiMessengerFill} from '@react-icons/all-files/ri/RiMessengerFill';
import {RiInstagramFill} from '@react-icons/all-files/ri/RiInstagramFill';

import SingleSocialIcon from './SingleSocialIcon';

const SocialIcons = ({postUrl, className = ''}) => {
  const facebookUrl = ``;
  const messagerUrl = ``;
  const twitterUrl = ``;
  const linkedinUrl = ``;
  const redditUrl = ``;
  const telegramUrl = ``;
  const emailUrl = ``;
  const clipboardUrl = ``;

  const clipboardSharingUrl = ``;

  const icons = [
    {
      sharingUrl: facebookUrl,
      Icon: ImFacebook,
      socialIconClassName: 'hover:text-blue-700',
      label: 'Share via Facebook',
      active: true,
    },
    {
      sharingUrl: messagerUrl,
      Icon: RiMessengerFill,
      socialIconClassName: 'hover:text-sky-500',
      label: 'Share via Facebook',
      active: true,
    },
    {
      sharingUrl: twitterUrl,
      Icon: FaTwitter,
      socialIconClassName: 'hover:text-blue-400',
      label: 'Share via Twitter',
      active: true,
    },
    {
      sharingUrl: '#',
      Icon: ImPinterest2,
      socialIconClassName: 'hover:text-red-600',
      label: 'Share via Pinterest',
      active: false,
    },
    {
      sharingUrl: '#',
      Icon: RiInstagramFill,
      socialIconClassName: 'hover:text-red-600',
      label: 'Integram',
      active: true,
    },
    {
      sharingUrl: linkedinUrl,
      Icon: FaLinkedin,
      socialIconClassName: 'hover:text-blue-500',
      label: 'Share via Linkedin',
      active: false,
    },
    {
      sharingUrl: redditUrl,
      Icon: AiOutlineReddit,
      socialIconClassName: 'hover:text-orange-500',
      label: 'Share via Reddit',
      active: false,
    },
    {
      sharingUrl: telegramUrl,
      Icon: FaTelegram,
      socialIconClassName: 'hover:text-blue-500',
      label: 'Share via Telegram',
      active: false,
    },
    {
      sharingUrl: emailUrl,
      Icon: CgMail,
      socialIconClassName: 'hover:text-neutral-800',
      label: 'Share via Email',
      active: false,
    },
    {
      sharingUrl: clipboardSharingUrl,
      Icon: BiLinkAlt,
      socialIconClassName: 'hover:text-rose-500',
      label: 'Share via ClipBoard',
      active: false,
    },
  ];

  return (
    <div
      className={`${className} flex flex-row items-center gap-3 lg:justify-center`}
    >
      {icons
        .filter((item) => item.active === true)
        .map((icon, index) => (
          <SingleSocialIcon
            key={index}
            url={icon.sharingUrl}
            Icon={icon.Icon}
            className={icon.socialIconClassName}
            ariaLabel={icon.label}
          />
        ))}
    </div>
  );
};

export default memo(SocialIcons);
