const FormData = require('form-data');

/**
 * Convert form data in JSON format to FormData format. If form has file, then attach file to form
 * @param {Object} json JSON object
 * @param {any} attachedFile Attached file
 * @returns form data that combine JSON object properties and attached file
 */
const jsonToFormData = (json, attachedFile) => {
  try {
    const form = new FormData();
    for (let k in json) {
      if (!json[k]) {
        form.append(k, '');
      } else {
        form.append(k, json[k]);
      }
    }
    if (attachedFile) {
      form.append('file', attachedFile.buffer, attachedFile.originalname);
    }
    return form;
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

/**
 * Convert file size in byte to string
 * @param {Number} fileSize File size (in byte)
 * @returns Converted file size in string. Eg: `fileSize`=`1100` => `1.1KB`
 */
const getFileSizeString = (fileSize) => {
  if (fileSize >= Math.pow(10, 9)) {
    return `${
      Math.round((fileSize / Math.pow(10, 9) + Number.EPSILON) * 100) / 100
    } GB`;
  }
  if (fileSize >= Math.pow(10, 6)) {
    return `${
      Math.round((fileSize / Math.pow(10, 6) + Number.EPSILON) * 100) / 100
    } MB`;
  }

  return `${
    Math.round((fileSize / Math.pow(10, 3) + Number.EPSILON) * 100) / 100
  } KB`;

  // if (fileSize >= Math.pow(10, 3)) {
  //   return `${
  //     Math.round((fileSize / Math.pow(10, 3) + Number.EPSILON) * 100) / 100
  //   } KB`;
  // }
  // return `${Math.round((fileSize + Number.EPSILON) * 100) / 100} bytes`;
};

/**
 * Convert Wordpress ID to Meilisearch valid ID: alphanumeric characters (a-z, A-Z, 0-9), hyphens (-), and underscores (_).
 * @param {string} id input ID
 * @returns
 */
const WPIdToSearchId = (id) => {
  return id.replace(/[^a-z-0-9]+/g, '-');
};

module.exports = {jsonToFormData, getFileSizeString, WPIdToSearchId};
