import React from 'react';
import {AiOutlineClose} from '@react-icons/all-files/ai/AiOutlineClose';
import {AiOutlineInfoCircle} from '@react-icons/all-files/ai/AiOutlineInfoCircle';
import OverlayWrapper from '../../Wrapper/OverlayWrapper';
import {TailwindTransitions} from '../../../utils/library/transitions/transitions';

/**
 * A Simple popup with 2 buttons to notify about something
 * @param {Object} props component props
 * @param {JSX.Element} props.Icon icon
 * @param {string} props.message popup message
 * @param {string} props.messageClassName popup message
 * @param {string} props.btnPrimaryLabel Label of primary button
 * @param {string} props.btnPrimaryClassName Class name of primary button
 * @param {string} props.btnSecondaryLabel Label of secondary button. If not defined, the button will be hidden.
 * @param {string} props.btnSecondaryClassName Class name of secondary button
 * @param {string} props.btnCloseDisplay Whether show button close or not
 * @param {string} props.btnCloseClassName Class name of close button
 * @param {()=>{}} props.onCloseClick Callback trigger when Close button is clicked
 * @param {()=>{}} props.onPrimaryClick Callback trigger when Primary button is clicked
 * @param {()=>{}} props.onSecondaryClick Callback trigger when Secondary button is clicked
 * @param {()=>{}} props.onOutsideClick Callback trigger when overlay background is clicked
 * @returns
 */
const PopupNotify = ({
  className = 'rounded-lg bg-white shadow',
  Icon = () => (
    <AiOutlineInfoCircle
      size="1rem"
      className="mx-auto mb-4 h-14 w-14 text-primary"
    />
  ),
  message = 'Notification',
  messageClassName = 'mb-5 text-lg font-normal',
  btnPrimaryLabel = 'OK',
  btnPrimaryClassName = 'mr-2 rounded-md border border-transparent bg-primary px-5 py-2.5 text-center text-sm font-medium text-white hover:bg-primary/80 focus:outline-none',
  btnSecondaryLabel,
  btnSecondaryClassName = 'rounded-md border border-primary px-5 py-2.5 text-center text-sm font-medium text-primary hover:bg-primary hover:text-white focus:outline-none',
  btnCloseDisplay = true,
  btnCloseClassName = 'rounded-lg bg-transparent p-1.5 text-sm text-neutral-500 hover:bg-neutral-200 hover:text-neutral-500',
  onCloseClick,
  onPrimaryClick,
  onSecondaryClick,
  onOutsideClick,
}) => {
  return (
    <OverlayWrapper
      isDisplaying={true}
      config={{
        background: {
          before: 'bg-black/20', // before transition
          after: 'bg-black/20', // after transition
        },
      }}
      onBgClicked={onOutsideClick}
    >
      <div className="flex h-full flex-col">
        <div className="relative m-auto w-full max-w-md">
          <div className={`relative mx-5 md:mx-0 ${className}`}>
            {btnCloseDisplay && (
              <button
                type="button"
                className={`absolute top-3 right-2.5 ml-auto inline-flex items-center ${TailwindTransitions.default[300]} ${btnCloseClassName}`}
                onClick={onCloseClick}
              >
                <AiOutlineClose size={18} />
                <span className="sr-only">Close modal</span>
              </button>
            )}
            <div className="p-6 text-center">
              <Icon />
              <h3 className={messageClassName}>{message}</h3>
              <button
                type="button"
                className={`inline-flex items-center ${TailwindTransitions.default[300]} ${btnPrimaryClassName}`}
                onClick={onPrimaryClick}
              >
                {btnPrimaryLabel}
              </button>

              {btnSecondaryLabel?.trim().length > 0 && (
                <button
                  type="button"
                  className={`inline-flex items-center ${TailwindTransitions.default[300]} ${btnSecondaryClassName}`}
                  onClick={onSecondaryClick}
                >
                  {btnSecondaryLabel}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    </OverlayWrapper>
  );
};

export default PopupNotify;
