import {AiOutlineDownload} from '@react-icons/all-files/ai/AiOutlineDownload';
import {BiErrorCircle} from '@react-icons/all-files/bi/BiErrorCircle';
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';
import {useDropzone} from 'react-dropzone';
import {addFileNameSuffix} from '../../../utils/helperFunctions';
import DropzoneUploadSingleFile from './DropzoneUploadSingleFile';

/**
 * A Dropzone field component for file uploading. User can drag and drop files here or click to select file.
 * @param {Object} props
 * @param {string}  props.fieldName Formik field name of this dropzone
 * @param {(name: String, values)=>{}} props.setValues Formik setValues function
 * @param {Object} props.acceptedTypes React Dropzone accepted file types. Default: accept all files.
 *
 * Reference: https://react-dropzone.js.org/#section-accepting-specific-file-types .
 * @param {Number} props.maxFileSize Max accept file size (in byte). Default: 10MB
 *
 * Eg: 1MB = 1 * 1024 * 1024.
 * @param {Number} props.maxFiles Max number of files user can upload. Default: 5
 * @returns
 */
const DropzoneField = forwardRef(
  (
    {
      defaultClassName = {
        root: 'border-dashed border-2 border-neutral-500',
        p: '',
        em: '',
      },
      rejectClassName = {
        root: 'border-dashed border-2 border-neutral-500',
        p: '',
      },
      acceptClassName = {
        root: 'border-dashed border-2 border-neutral-500',
        p: '',
      },
      fieldName,
      setValues,
      note,
      acceptedTypes = {},
      rejectErrMsg = '',
      maxFileSize = 10 * 1024 * 1024, // 10MB
    },
    ref
  ) => {
    const [files, setFiles] = useState([]);

    useImperativeHandle(ref, () => ({
      resetValues() {
        setFiles([]);
      },
    }));

    const {getRootProps, getInputProps, isDragAccept, isDragReject} =
      useDropzone({
        accept: acceptedTypes,
        maxSize: maxFileSize,
        multiple: true,
        onDrop: (acceptedFiles, rejFiles) => {
          if (acceptedFiles.length === 0 && rejFiles.length === 0) return;
          const mappedAcc = acceptedFiles.map((file) => ({
            file,
            uploaded: false,
            fileName: changeFileName(file.name),
            // check duplicate file
            errors: files.find(
              (fileWrapper) => fileWrapper.file.name === file.name
            )
              ? [
                  {
                    code: 'file-duplicate',
                  },
                ]
              : [],
          }));

          // Filtered all invalid file types
          const filteredRej = rejFiles
            .filter((file) => file.errors[0].code !== 'file-invalid-type')
            .map((file) => ({...file, fileName: file.file.name}));

          setFiles((prev) => [...prev, ...mappedAcc, ...filteredRej]);
        },
      });

    useEffect(() => {
      setValues(fieldName, files);
    }, [files, setValues, fieldName]);

    // Handle upload
    function onUpload(file, url) {
      setFiles((prev) =>
        prev.map((fileWrapper) => {
          // Add url to fileWrapper
          if (fileWrapper.file === file) {
            return {...fileWrapper, uploaded: true, url};
          }
          return fileWrapper;
        })
      );
    }

    // Handle delete
    function onDelete(file) {
      setFiles((prev) =>
        prev.filter((fileWrapper) => fileWrapper.file !== file)
      );
    }

    // Handle error
    function onError(file, error) {
      setFiles((prev) =>
        prev.map((fileWrapper) => {
          if (fileWrapper.file === file) {
            fileWrapper.errors.push(error);
          }
          return fileWrapper;
        })
      );
    }

    let renderText = '';
    let rootClassName = '';

    if (isDragAccept) {
      rootClassName = `${acceptClassName.root}`;
      renderText = (
        <>
          <AiOutlineDownload size={48} className={acceptClassName.icon} />
          <p className={acceptClassName.p}>Kéo thả tệp vào đây</p>
        </>
      );
    } else if (isDragReject) {
      rootClassName = `${rejectClassName.root}`;
      renderText = (
        <>
          <BiErrorCircle size={48} className={`${rejectClassName.icon}`} />
          <p className={rejectClassName.p}>{rejectErrMsg}</p>
        </>
      );
    } else {
      rootClassName = `${defaultClassName.root}`;
      renderText = (
        <>
          <AiOutlineDownload size={32} className={defaultClassName.icon} />
          <p className={defaultClassName.p}>
            <span className="underline underline-offset-2">
              Kéo thả tệp vào đây
            </span>
            {' để tải lên, hoặc '}
            <span className="underline underline-offset-2">nhấn vào đây</span>
            {' để chọn file'}
          </p>
          {acceptedTypes && Object.keys(acceptedTypes).length !== 0 && (
            <em className={defaultClassName.em}>{note}</em>
          )}
        </>
      );
    }

    return (
      <>
        <div
          {...getRootProps({
            className: `${rootClassName} cursor-pointer`,
          })}
        >
          <input {...getInputProps()} aria-label="Upload attachments" />
          {renderText}
        </div>
        {files.length > 0 && (
          <div
            className={`mt-5 flex max-h-[250px] flex-col flex-nowrap items-stretch gap-2 overflow-y-auto whitespace-nowrap rounded-md border border-neutral-200 p-3 lg:max-h-[300px]`}
          >
            {files.map((fileWrapper, idx) => (
              <DropzoneUploadSingleFile
                key={idx}
                file={fileWrapper.file}
                fileName={fileWrapper.fileName}
                isUploaded={fileWrapper.uploaded}
                onDelete={onDelete}
                onUpload={onUpload}
                onError={onError}
                errors={fileWrapper.errors}
              />
            ))}
          </div>
        )}
      </>
    );
  }
);

export default DropzoneField;

/**
 * Add timestamp to file name to avoid overwrite on storage server. Also add folder path if specified
 * @param {String} fileName
 * @returns
 */
function changeFileName(fileName) {
  return `${process.env.S3_FOLDER_PATH ?? ''}${addFileNameSuffix(
    fileName,
    `-${Date.now()}`
  )}`.trim();
}
