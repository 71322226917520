import React, {useEffect} from 'react';

/**
 * A Custom Hook to handle `keydown` event
 * @param {string} keyCode Key Code to be handled.
 *
 * Reference: https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/code
 * @param {()=>{}} handler Handler function
 * @returns
 */
const useKeydownHandler = (keyCode, handler = () => {}) => {
  const isSSR = typeof window == 'undefined';

  useEffect(() => {
    if (isSSR) return;

    const keyHandler = (e) => {
      if (e.code === keyCode) {
        handler();
      }
    };

    window.addEventListener('keydown', keyHandler);

    return () => {
      window.removeEventListener('keydown', keyHandler);
    };
  }, [isSSR, handler, keyCode]);
  return <div>useEscHandler</div>;
};

export default useKeydownHandler;
