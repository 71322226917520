import {ErrorMessage, Field} from 'formik';
import React from 'react';
import {TailwindTransitions} from '../../utils/library/transitions/transitions';
import InputField from '../_baseComponents/Field/InputField';
import {TbAlertCircle} from '@react-icons/all-files/tb/TbAlertCircle';

/**
 *
 * @param {Object} props
 * @param {Object} props.formik - Does not destructering, you just need to pass a formik Object.
 * @param {string} props.inputName - Name of input field, you set up in formik Object.
 * @param {string} props.type - Type of input element.
 * @param {string} props.placeholder - Placeholder of input element.
 * @param {function} props.handleRequiredBlur - A function handle customize on blur for formik Field.
 * @param {string} props.className - className for this field
 *
 * @returns
 */

const MaterialField = ({
  formik,
  inputName,
  type,
  placeholder,
  handleRequiredBlur,
  className,
}) => {
  return (
    <>
      <label
        className={`flex h-14 flex-row justify-between rounded-md border-2 border-white  bg-white/60 px-3 pt-4 pb-1 focus-within:border-primary  ${className}`}
      >
        <div className="relative flex w-full flex-col" htmlFor={inputName}>
          <Field
            id={inputName}
            name={inputName}
            type={type}
            required
            className="peer w-full translate-y-1"
            as={InputField}
            customizeOnBlur={handleRequiredBlur}
          />

          <span
            className={` ${
              TailwindTransitions.default[300]
            } absolute translate-y-0 peer-valid:-translate-y-2 peer-valid:text-xs  peer-focus:-translate-y-2 peer-focus:text-xs ${
              formik.errors[inputName] && formik.touched[inputName]
                ? `text-red-500 peer-invalid:-translate-y-2 peer-invalid:text-xs`
                : `text-gray-500 peer-valid:text-gray-300 peer-focus:text-gray-300`
            }`}
          >
            {placeholder}
          </span>
        </div>

        {formik.errors[inputName] && formik.touched[inputName] && (
          <div>
            <TbAlertCircle className="h-6 w-5 -translate-y-1 stroke-red-500 stroke-2" />
          </div>
        )}
      </label>
      <ErrorMessage
        name={inputName}
        className={`${TailwindTransitions.default[300]} pl-2 text-xs text-red-500`}
        component="p"
      />
    </>
  );
};

export default MaterialField;
